import React from 'react';
import { Image } from 'components/atoms';
import logo from 'assets/images/logo-text.png'
import favicon from 'assets/images/favicon.png'

export function Logo(props) {
  return (
    <a href="/" title="Lightmap">
      <Image className={props.logoImage}
        src={props.themeMode === 'light' ? favicon : favicon}
        alt="Lightmap" lazy={false} />
    </a>
  );
}
