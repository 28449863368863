import React from "react";
import { Helmet } from "react-helmet";


export default function SEO({ title = '', image = '', url = '', description = '', lang = 'en' }) {

  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="image" content={`http://${process.env.GATSBY_HOST}${image}`} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      {/* {isBlogPost ? <meta property="og:type" content="article" /> : null} */}
      <meta property="og:title" content={`http://${process.env.GATSBY_HOST}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`http://${process.env.GATSBY_HOST}${image}`} />
      <meta property="fb:app_id" content={process.env.FACEBOOK_APP_ID} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={seo.social.twitter} /> */}
      <meta name="twitter:title" content={title} />
      {/* <meta name="twitter:description" content={description} /> */}
      <meta property="twitter:image" content={`http://${process.env.GATSBY_HOST}${image}`} />

    </Helmet>
  );
}
